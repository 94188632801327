<template>
  <div id="settings">
    <v-container fluid class="my-12">
      <v-row>
        <v-col cols="12" md="10" offset-md="1">
          <h2 class="mb-4">Profile</h2>
          <v-card>
            <v-form ref="profileForm" @submit.prevent="updateProfile()">
              <v-card-text>
                <div class="d-flex justify-space-around">
                  <v-text-field
                    id="firstName"
                    v-model="user.firstName"
                    label="First Name"
                    name="firstName"
                    prepend-icon="person"
                    type="text"
                    required
                    :rules="firstNameRules"
                  />
                  <v-text-field
                    id="lastName"
                    v-model="user.lastName"
                    label="Last Name"
                    name="lastName"
                    type="text"
                    required
                    :rules="lastNameRules"
                    class="ml-4"
                  />
                </div>
                <v-text-field
                  id="email"
                  v-model="user.email"
                  label="e-Mail"
                  name="email"
                  prepend-icon="alternate_email"
                  type="text"
                  required
                  :rules="emailRules"
                  disabled
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  type="submit"
                  text
                  :disabled="profileFormDisabled"
                  >SAVE</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>

          <h2 class="mt-8 mb-4">Change Password</h2>
          <v-card>
            <v-form ref="passwordForm" @submit.prevent="updatePassword()">
              <v-card-text>
                <div class="d-flex justify-space-around">
                  <v-text-field
                    id="password"
                    v-model="user.password"
                    label="Password"
                    name="password"
                    prepend-icon="lock"
                    type="password"
                    required
                    min="6"
                    :rules="passwordRules"
                  />
                  <v-text-field
                    id="passwordConfirm"
                    v-model="user.passwordConfirm"
                    label="Confirm Password"
                    name="passwordConfirm"
                    type="password"
                    required
                    min="6"
                    :rules="passwordConfirmRules"
                    class="ml-4"
                  />
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  type="submit"
                  text
                  :disabled="passwordFormDisabled"
                  >SAVE</v-btn
                >
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";

export default {
  data: () => ({
    user: {
      id: "",
      uid: "",
      firstName: "",
      lastName: "",
      displayName: "",
      email: "",
      password: "",
      passwordConfirm: "",
      roles: []
    },
    profileFormDisabled: false,
    passwordFormDisabled: false
  }),
  computed: {
    firstNameRules() {
      return [v => !!v || "Required"];
    },
    lastNameRules() {
      return [v => !!v || "Required"];
    },
    emailRules() {
      return [
        v => !!v || "Required",
        v => /.+@.+\..+/.test(v) || "Invalid e-mail address"
      ];
    },
    passwordRules() {
      return [
        v => !!v || "Required",
        v => (v && v.length >= 6) || "The password must be at least 6 characters"
      ];
    },
    passwordConfirmRules() {
      return [
        v => !!v || "Please confirm your password",
        v => (v && v.length >= 6) || "The password must be at least 6 characters",
        v => v === this.user.password || "The Password not confirmed"
      ];
    }
  },
  methods: {
    async getProfile() {
      const user = firebase.auth().currentUser;

      if (user != null) {
        this.user.uid = user.uid;
        this.user.email = user.email;

        try {
          // Veritabanından profil bilgilerini al
          const qsProfile = await firebase
            .firestore()
            .collection("users")
            .where("uid", "==", this.user.uid)
            .get();

          const docProfile = qsProfile.docs[0];
          this.user.id = docProfile.id;
          this.user.firstName = docProfile.data().firstName;
          this.user.lastName = docProfile.data().lastName;
          this.user.displayName = `${this.user.firstName} ${this.user.lastName}`;
          this.user.roles = docProfile.data().roles;
        } catch (err) {
          this.$notify({
            type: "error",
            text: "User infos have not been taken"
          });

          console.log(err);
        }
      }
    },
    async updateProfile() {
      const form = this.$refs.profileForm;

      if (form.validate()) {
        this.profileFormDisabled = true;

        this.$notify({
          text: "Your profile updating..."
        });

        try {
          const user = firebase.auth().currentUser;

          this.user.displayName = `${this.user.firstName} ${this.user.lastName}`;

          // Profil bilgilerini güncelle
          await user.updateProfile({
            displayName: this.user.displayName
          });

          // e-Posta adresini güncelle
          //TODO: e-Posta güncelleme protokolünü oluştur.
          await user.updateEmail(this.user.email);

          await firebase
            .firestore()
            .doc("users/" + this.user.id)
            .update({
              firstName: this.user.firstName,
              lastName: this.user.lastName,
              email: this.user.email
            });

          this.$store.commit("auth/setUser", {
            id: this.user.id,
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            displayName: this.user.displayName,
            email: this.user.email,
            roles: this.user.roles
          });

          // Mesaj göster
          this.$notify({
            text: "Your profile has been updated.",
            type: "success"
          });
        } catch (error) {
          this.$notify({
            type: "error",
            text: "Your profile has not been updated."
          });
        } finally {
          this.profileFormDisabled = false;
        }
      }
    },
    async updatePassword() {
      const form = this.$refs.passwordForm;
      if (form.validate()) {
        this.passwordFormDisabled = true;
        this.$notify({
          text: "Your password updating..."
        });

        try {
          const user = firebase.auth().currentUser;

          //TODO: Parola güncelleme protokolünü düzenle
          await user.updatePassword(this.user.password);

          this.$notify({
            type: "success",
            text: "Your password has been updated."
          });
        } catch (err) {
          let msg = "";
          if (err.code == "auth/requires-recent-login") {
            msg = `Your have to re-sign in in order to change your password. <a href="/giris?returnUrl=${this.$route.fullPath}" class="white--text font-weight-bold">Sign in</a>`;
          } else {
            msg = "Your password has not been updated.";
          }

          this.$notify({
            text: msg,
            type: "error",
            duration: -1
          });
        } finally {
          this.passwordFormDisabled = false;
          form.reset();
        }
      }
    }
  },
  created() {
    this.getProfile();
  }
};
</script>
